<template>
    <div class="contain" v-loading="isRequestLoading">
        <div class="title">
            <h2>{{infoData.purchase.title}}</h2>
        </div>

        <div class="content">
            <div class="head">
                <div class="times">
                    <span class="time">采购发布时间：{{infoData.purchase.createdAt}}</span>
                    <span class="time">报价截止时间：{{infoData.purchase.stopAt}}</span>
                    <span class="time">期望收货时间：{{infoData.purchase.receiptAt}}</span>
                </div>

                <div class="operate" v-if="type === '1'">
                    <el-button @click="$router.push({path: '/shop/buying/offer', query: {id: id, companyId: companyId, companyName: companyName}})" v-if="infoData.status === 0">我要报价</el-button>
                    <el-button @click="$router.push({path: '/shop/buying/offer', query: {id: id, companyId: companyId, companyName: companyName}})" v-else>已报价</el-button>
                    <span class="text">已有 <span>{{infoData.quotedNum}}</span> 条报价</span>
                </div>

                <div class="times right" v-if="type === '2'">
                    <span class="time">采购单号：{{infoData.purchase.code}}</span>
                    <span class="time" v-if="infoData.purchase.isRun === 1">采购单状态： <span>进行中</span></span>
                    <span class="time" v-if="infoData.purchase.isRun === 2">采购单状态： <span>已关闭</span></span>
                    <span class="time" v-if="infoData.purchase.isRun === 3">采购单状态： <span>已过期</span></span>
                </div>
            </div>

            <div class="subTitle">
                <h1>采购商品</h1>
            </div>

            <div class="goodsInfo">
                <div class="line">
                    <span class="label">商品名称</span>
                    <span class="label">商品规格</span>
                    <span class="label">采购量</span>
                    <span class="label">采购价格</span>
                    <span class="label"></span>
                </div>
                <div class="line">
                    <span class="text">{{infoData.purchase.goodName}}</span>
                    <span class="text">{{infoData.purchase.sku}}</span>
                    <span class="text">{{infoData.purchase.num}}{{infoData.purchase.unitName}}</span>
                    <span class="text"><span>¥ {{infoData.purchase.price}}</span> {{getPriceStatus(infoData.purchase.priceStatus)}}</span>
                    <span class="text">
                                <el-image v-if="infoData.purchase.imageUrl"
                                          style="width: 50px; height: 50px"
                                          fit="contain"
                                          :src="imageURL + infoData.purchase.imageUrl"
                                          :preview-src-list="[imageURL + infoData.purchase.imageUrl]">
                                </el-image>
                            </span>
                </div>
            </div>

            <template v-if="type === '2'">
                <div class="subTitle">
                    <h1>联系方式</h1>
                </div>

                <div class="contactDesc" v-if="infoData.purchase.contactStatus === 1 && infoData.status === 0">
                    <span class="text">报价后可见</span>
                </div>

                <div class="contactDesc" v-else>
                    <span class="text">联系人：{{infoData.purchase.contactName}}</span>
                    <span class="text">联系电话：{{infoData.purchase.contactPhone}}</span>
                </div>
            </template>

            <div class="subTitle">
                <h1>详细说明</h1>
            </div>

            <div class="buyDesc">{{infoData.purchase.content}}</div>

            <div class="footBtn" v-if="type === '1'">
                <el-button @click="$router.go(-1)">返 回</el-button>
            </div>
        </div>

        <template v-if="type === '2'">
            <div class="offerContain" v-if="infoData.status === 0">
                <el-form ref="form" :model="form" :rules="rules" size="small">
                    <el-form-item label="供应数量：" prop="num" class="half" label-width="80px">
                        <el-input v-model="form.num" placeholder="请输入供应商品数量"></el-input>
                    </el-form-item>

                    <el-form-item label="单位：" prop="unitId" class="half" label-width="160px">
                        <el-select v-model="form.unitId" placeholder="请选择单位">
                            <el-option v-for="(item, index) in unitList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="价格说明：" prop="price" label-width="80px">
                        <el-input v-model="form.price" placeholder="请输入供应商品单价"></el-input>
                        <span class="text">元</span>
                        <el-radio-group v-model="form.priceStatus">
                            <el-radio :label="1">可议价</el-radio>
                            <el-radio :label="2">不可议价</el-radio>
                            <el-radio :label="3">面议</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="标题：" prop="title" class="half" label-width="80px">
                        <el-input v-model="form.title" placeholder="请输入标题"></el-input>
                    </el-form-item>

                    <el-form-item label="商品图片：" prop="image" label-width="80px">
                        <el-upload
                                class="avatar-uploader"
                                :action="uploadURL"
                                :before-upload="beforeUploadImage"
                                :on-success="imageUrlUpload"
                                :show-file-list="false">
                            <img v-if="form.imageUrl" :src="imageURL + form.imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M</div>
                    </el-form-item>

                    <el-form-item label="报价说明：" prop="content" label-width="80px">
                        <el-input v-model="form.content" type="textarea" :rows="4" placeholder="请输入供应商品单价"></el-input>
                    </el-form-item>

                    <el-form-item label="公司名称：" prop="companyName" class="half" label-width="80px">
                        <el-input v-model="form.companyName" placeholder="请输入公司名称"></el-input>
                    </el-form-item>

                    <el-form-item label="联系人：" prop="contactName" class="half" label-width="160px">
                        <el-input v-model="form.contactName" placeholder="请输入联系人"></el-input>
                    </el-form-item>

                    <el-form-item label="联系电话：" prop="contactPhone" class="half" label-width="80px">
                        <el-input v-model="form.contactPhone" placeholder="请输入联系电话"></el-input>
                    </el-form-item>

                    <el-form-item label="预计交货日期：" prop="deliveryAt" class="half" label-width="160px">
                        <el-date-picker type="date" placeholder="选择预计交货日期" v-model="form.deliveryAt" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                    </el-form-item>

                    <el-form-item class="footer">
                        <el-button type="primary" class="sure" :loading="isSubmitLoading" @click="submitForm('form')">确认，提交报价单</el-button>
                        <el-button class="back" @click="$router.go(-1)">返 回</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <template v-else>
                <div class="titleElse">
                    <h2>我的报价单</h2>
                </div>

                <div class="offerDetail">
                    <div class="subTitle">
                        <h1>报价单内容</h1>
                    </div>

                    <div class="info">
                        <span class="text">报价标题：{{offerData.title}}</span>
                        <span class="text">供应数量：{{offerData.num}}{{offerData.unitName}}</span>
                        <span class="text">价格说明：￥ {{offerData.price}} （{{getPriceStatus(offerData.priceStatus)}}）</span>
                        <div class="line">
                            <span class="text">商品图片：</span>
                            <el-image
                                    v-if="offerData.imageUrl"
                                    style="width: 124px; height: 124px"
                                    fit="contain"
                                    :src="imageURL + offerData.imageUrl"
                                    :preview-src-list="[imageURL + offerData.imageUrl]">
                            </el-image>
                        </div>
                        <span class="text">报价说明：{{offerData.content}}</span>
                        <span class="text">公司名称：{{offerData.companyName}}</span>
                        <span class="text">联系人：{{offerData.contactName}}</span>
                        <span class="text">联系电话：{{offerData.contactPhone}}</span>
                        <span class="text">预计交货日期：{{offerData.deliveryAt}}</span>
                    </div>
                </div>

                <div class="footBtn pb32" style="justify-content: center">
                    <el-button @click="$router.go(-1)">返 回</el-button>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
  import {beforeUploadImage} from "../../../../utils/method";
  import {imageURL, uploadURL} from "../../../../utils/config";
  import {
    getBuyingInfo,
    postBuyingOffer,
    getBuyingOfferInfo,
    getUnit,
  } from "../../../../api/shop/supplyBuying";

  export default {
    name: "buyingInfo",
    props:{
      id: {
        type: String,
        default: ''
      },
      companyId: {
        type: String,
        default: ''
      },
      companyName: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: '1' // 1-详情，2-报价
      }
    },
    data () {
      return {
        infoData: {
          purchase: {}
        },
        isRequestLoading: false,
        imageURL: imageURL,
        uploadURL: uploadURL,
        beforeUploadImage: beforeUploadImage,
        form: {
          num: '',
          unitId: null,
          price: '',
          priceStatus: 1,
          title: '',
          imageUrl: '',
          content: '',
          companyName: '',
          contactName: '',
          contactPhone: '',
          deliveryAt: ''
        },
        rules: {
          num: [
            {required: true, message: '请输入供应商品数量', trigger: 'blur'},
          ],
          unitId: [
            {required: true, message: '请选择单位', trigger: 'change'},
          ],
          price: [
            {required: true, message: '请输入供应商品单价', trigger: 'blur'},
          ],
          title: [
            {required: true, message: '请输入标题', trigger: 'blur'},
          ],
          companyName: [
            {required: true, message: '请输入公司名称', trigger: 'blur'},
          ],
          contactName: [
            {required: true, message: '请输入联系人', trigger: 'blur'},
          ],
          contactPhone: [
            {required: true, message: '请输入联系电话', trigger: 'blur'},
            { pattern: '^([1]\\d{10}|([\\(（]?0[0-9]{2,3}[）\\)]?[-]?)?([2-9][0-9]{6,7})+(\\-[0-9]{1,4})?)$', message: '请输入正确的联系电话', trigger: 'blur'}
          ],
          deliveryAt: [
            {required: true, message: '选择预计交货日期', trigger: 'change'},
          ]
        },
        isSubmitLoading: false,
        unitList: [],
        offerData: {}
      }
    },
    created() {
      this.getInfo(this.id)
    },
    watch: {
      id (val) {
        this.getInfo(val)
      }
    },
    methods: {
      getInfo (id) {
        this.isRequestLoading = true
        getBuyingInfo(id).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.infoData = res.data
            if (this.type === '2' && res.data.status === 0) {
              this.getUnit()
            }

            if (this.type === '2' && res.data.status !== 0) {
              this.getBuyingOfferInfo()
            }
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getBuyingOfferInfo () {
        getBuyingOfferInfo(this.id).then(res => {
          if (res.code === 0) {
            if (res.data.quotedPrices && res.data.quotedPrices.length > 0) {
              this.offerData = res.data.quotedPrices[0]
            }
          }
        })
      },
      imageUrlUpload (res) {
        if (res.code === 0) {
          this.form.imageUrl = res.data
        }
      },
      getUnit () {
        getUnit().then(res => {
          if (res.code === 0) {
            this.unitList = res.data
          }
        })
      },
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isSubmitLoading = true
            postBuyingOffer({
              data: {
                title: this.form.title,
                num: this.form.num,
                unitId: this.form.unitId,
                unitName: this.unitList.filter(item => item.id === this.form.unitId)[0].name,
                imageUrl: this.form.imageUrl,
                deliveryAt: this.form.deliveryAt,
                price: this.form.price,
                priceStatus: this.form.priceStatus,
                content: this.form.content,
                companyName: this.form.companyName,
                contactName: this.form.contactName,
                contactPhone: this.form.contactPhone,
                purchasId: this.id
              }
            }).then(res => {
              this.isSubmitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '采购单报价成功',
                  showClose: true,
                  type: 'success'
                })
                this.getInfo(this.id)
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          } else {
            return false;
          }
        });
      },
      getPriceStatus (status) {
        switch (status) {
          case 1:
            return '可议价';
          case 2:
            return '不可议价';
          case 3:
            return '面议';
        }
      },
    }
  }
</script>
